import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Divider, Image, Text } from "@chakra-ui/core";
import { Card } from "../custom/Card";
import volkswagen from "../../../assets/Volkswagen_logo_2019.svg";
import "./result.css";
import skoda from "../../../assets/skoda.png"
const Results = (props) => {
  console.log(props);
  return (
    <div
      style={{
        backgroundColor: "#103a2f",
        minHeight: "100%",
        paddingBottom: "1em",
      }}
    >
      <Image src={props.image} size="100%" />
      <Box
        color="#fff"
        alignSelf="center"
        // justifyContent="space-between"
        display="flex"
        padding="1em"
        className="heading"
      >
        <Image src={skoda} size="10%" />{" "}
        <Text
          fontSize="4xl"
          color="white"
          style={{
            fontSize: "21px",
            letterSpacing: ".7px",
            borderBottom: "1px solid white",
            // fontFamily: "DM Sans",
            // textTransform: "uppercase",
            fontWeight: "500",
            marginLeft: "15px",
          }}
        >
          Results
        </Text>
      </Box>
      <Box d="flex" flexDirection="column" alignItems="center" p="1em">
        {props.results.map((result, i) => {
          return (
            <Card
              data={result.intent}
              key={result.intent.toString()}
              isVoice={false}
            />
          );
        })}{" "}
      </Box>
    </div>
  );
};

export default withRouter(Results);
