import React, {Component} from 'react';
import Chat from '../../components/Chat/Chat';
import axios from 'axios';
export default class ChatContainer extends Component {
  state = {
    message: '',
    results: [],
    loading: false,
    query: ''
  };

  fetchResponse = (msg) => {
    let query = msg;
    // let url = 'https://kkomh47ijuy6zprjajxagawehq0fgfnp.lambda-url.ap-south-1.on.aws/';
    let url = 'https://api.xanegin.assist.marutisuzuki.com/stage/query';
    let body = {
      // model_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/onnx/text/verbal-quantized.onnx',
      // labels_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/onnx/text/verbal.json',
      project: 'msil-assist',
      text: msg,
      limit: 3
    };
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'x-key': 'a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66'
      }
    };

    axios.post(url, body, options).then((response) => {
      this.setState({
        results: response.data.data.predictions,
        loading: false,
        query: query,
        message: ''
      });
    });
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchResponse(this.state.message);
    }
  };

  inputChange = (e) => {
    this.setState({message: e.target.value});
  };

  render() {
    return (
      <div>
        <Chat
          theme={this.props.theme}
          onChange={this.inputChange}
          message={this.state.message}
          loading={this.state.loading}
          results={this.state.results}
          fetchResponse={this.fetchResponse}
          _handleKeyDown={this._handleKeyDown}
          query={this.state.query}
        />
      </div>
    );
  }
}
