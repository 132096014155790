import React, { useEffect } from "react";
import "../../components/Home/styles.css";
import { Box, Input, IconButton, Spinner } from "@chakra-ui/core";
import { FaPaperPlane } from "react-icons/fa";
import { Widget } from "react-chat-widget";
import { Card } from "../custom/Card";
import "../Home/styles.css";

import "react-chat-widget/lib/styles.css";
export default function Chat(props) {
  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVH);
    window.addEventListener("orientationchange", setVH);
    setVH();

    return () => {
      window.removeEventListener("resize", setVH);
      window.removeEventListener("orientationchange", setVH);
    };
  }, []);
  return (
    // <div className="App">
    //   <Widget han/>
    // </div>
    <div
      style={{
        backgroundColor: "#103a2f",
        height: "calc(var(--vh, 1vh) * 100)",
        flexDirection: "column",
      }}
    >
      {props.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#2792BA"
          size="xl"
        />
      ) : (
        <div
          style={{
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "scroll",
            justifyContent: "space-between",
            backgroundColor: "#103a2f",
          }}
        >
          <Box d="flex" flexDirection="column" alignItems="center" p="1em">
            {props.query && (
              <div style={{ alignSelf: "flex-end" }}>
                <div
                  style={{
                    color: "white",

                    backgroundColor: "#103a2f",

                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    {props.query}
                  </p>
                </div>
              </div>
            )}
            {/* <div style={{ color: "#2792BA", alignSelf: "flex-end" }}>
              
            </div> */}
            {props.results &&
              props.results.map((result, i) => {
                return (
                  <Card
                    data={result.intent}
                    key={result.intent.toString()}
                    isVoice={false}
                  />
                );
              })}
          </Box>
        </div>
      )}
      <div
        style={{
          position: "fixed",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          bottom: 10,
          left: 20,
          width: "90%",
          border:"none",
          alignItems: "center",
          // backgroundColor: "#103a2f",
        }}
      >
        <Input
          placeholder="Enter your message"
          size="md"
          value={props.message}
          onChange={(e) => props.onChange(e)}
          borderRadius={50}
          onKeyDown={(e) => props._handleKeyDown(e)}
        />
        <IconButton
          colorScheme="blue"
          aria-label="Call Sage"
          fontSize="20px"
          icon={FaPaperPlane}
          disabled={!props.message}
          marginLeft={5}
          onClick={() => props.fetchResponse(props.message)}
        />
      </div>
    </div>
  );
}
