import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import "../node_modules/video-react/dist/video-react.css";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if ("serviceWorker" in navigator) {
//   // register service worker
//   navigator.serviceWorker.register("service-worker.js", {
//     scope: "https://demo.assist.marutisuzuki.com",
//   });
// }
// serviceWorkerRegistration.register("/service-worker.js", {
//   scope: "https://demo.assist.marutisuzuki.com",
// });
// serviceWorker.register();
