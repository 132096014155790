import { Box, Divider, Image, Text } from "@chakra-ui/core";
import Volkswagen_logo_text from "../../../assets/Volkswagen_logo_text.svg";
import React from "react";

const SkodaHeader = () => {
  return (
    <Box
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {/* <Box boxSize="sm">
        <Image
          src="https://i.imgur.com/rgK2zj0.png"
          alt="Dan Abramov"
          style={{
            height: "70px",
            width: "auto",
            maxWidth: "unset",
          }}
        />
      </Box> */}
      <Box boxSize="sm" style={{ marginTop: "6px" }}>
        {/* <Image
          src={Volkswagen_logo_text}
          alt="Dan Abramov"
          style={{
            height: "30px",
            marginTop: "10px",
          }}
        /> */}
        <Text
          fontSize="4xl"
          color="white"
          style={{
            fontSize: "21px",
            letterSpacing: ".7px",

            // fontFamily: "DM Sans",
            // textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
        Škoda Assist
        </Text>
      </Box>
      <Divider
        style={{ borderColor: "white", width: "100px", marginTop: "10px" }}
      />
    </Box>
  );
};

export default SkodaHeader;
