import React from "react";
import "./styles.css";
import { Box, Image } from "@chakra-ui/core";
import { FaCamera, FaMicrophone, FaRocketchat } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import SkodaHeader from "../Header/SkodaHeader";
import volkswagen from "../../../assets/Volkswagen_logo_2019.svg";
import skoda from "../../../assets/skoda.png"
const Home = (props) => {
  return (
    <div className="main">
      <SkodaHeader />
      <div
        className="camera"
        onClick={() => {
          props.history.push("/camera");
        }}
      >
        <Box as={FaCamera} size="36px" color="white" />
      </div>
      <div
        className="mic"
        onClick={() => {
          props.history.push("/voice");
        }}
      >
        <Box as={FaMicrophone} size="36px" color="white" />
      </div>
      {/* <div
        className="mic"
        
      >
        <Box as={FaMicrophone} size="36px" color="white" opacity={0.3}/>
      </div> */}
      <div
        className="text"
        onClick={() => {
          props.history.push("/text");
        }}
      >
        <Box as={FaRocketchat} size="36px" color="white" />
      </div>
      <Box className="xane_icon">
        <Image src={skoda} alt="Segun Adebayo" size="96px" />
      </Box>
    </div>
  );
};

export default withRouter(Home);
