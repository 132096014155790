import React from "react";
import { Switch, Route } from "react-router-dom";
import Camera from "../containers/Camera/Camera";
import Home from "../containers/Home/Home";
import Results from "../containers/Results/Results";
import Voice from "../containers/Voice/Voice";
import Chat from "../containers/Chat/Chat";
import { theme } from "@chakra-ui/core";

export const Routes = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        {/* <Home theme={theme} /> */}
        <Home theme={theme} />
      </Route>
      <Route exact path="/camera">
        <Camera theme={theme} />
      </Route>
      <Route exact path="/results">
        <Results theme={theme} />
      </Route>
      <Route exact path="/voice">
        <Voice />
      </Route>
      <Route exact path="/text">
        <Chat theme={theme}/>
      </Route>
    </Switch>
  );
};
