import React from 'react';
import {Box, Image, Badge} from '@chakra-ui/core';
import './card.css';
import {Player, BigPlayButton} from 'video-react';

const videos = {
  brake_system_warning_light: 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/compressed/brake_system_warning_light.mp4',
  illumination_indicator_light: 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/compressed/illumination_indicator_light.mp4',
  keyless_push_start_system_remote_controller: 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/compressed/keyless_push_start_system_remote_controller.mp4',
  seat_belt_reminder: 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/compressed/seat_belt_reminder.mp4',
  windshield_wipers_and_washers: 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/compressed/windshield_wipers_and_washers.mp4'
};

const feature_data = {
  brake_system_warning_light:
    'Three different types of operations exist depending on the vehicle’s specification.' +
    '\n' +
    '1) The light comes on briefly when the ignition switch is turned to the ON position or the engine switch is pressed to change the ignition mode to “ON”.' +
    '\n' +
    '2) The light comes on when the parking brake is engaged with the ignition switch in the “ON” position or the ignition mode ON.' +
    '\n' +
    '3) The light comes on when under either or both of above two conditions.',
  illumination_indicator_light: 'This indicator light comes on while the position lights, tail light and/or the headlights are on',
  keyless_push_start_system_remote_controller:
    'Central door locking system\
          1. To lock all doors, push the “LOCK” button once.\
      2. To unlock only the driver’s door, push the “UNLOCK” button once.\
      3. To unlock other doors, push the “UNLOCK” button once again.\
      Car locator function\
      Push both of the LOCK button and UNLOCK button for more than 3 seconds. The turn signal lights will blink for about 27.5 seconds. Also, the siren will sound for about 27.5 seconds at the same time.',
  seat_belt_reminder:
    "If there is a person sitting in the front passenger seat and the front passenger seat belt is unbuckled when the ignition switch is turned to ON position, the front pas­senger's seat belt reminder will activate." +
    "The seat belt reminder sensor detects whether a person is sitting in the front seat. The sensor of the front passenger's seat belt reminder is located in the seat cushion.",
  windshield_wipers_and_washers:
    'To turn the rear wiper on, twist the rear wiper switch on the end of the lever forward to the “ON” position. To turn the rear wiper off, twist the switch rearward to the “OFF” position.\
      With the rear wiper in the “OFF” position, twist the switch rearward and hold it there to spray window washer fluid and wipe.\
      With the rear wiper in the “ON” position, turn the switch forward and hold it there to spray window washer fluid.'
};

// const feature_data = {
//   "climatronic-automatic-air-conditioning-system":
//     "On clicking this button, following menu is displayed:\r\n1. Display smart air conditioning with pre-determined pre-set options.\r\n2. Show classic settings for the air conditioning.\r\n3. Air Care \r\n4. Further settings for air conditioning",
//   "fuel-indicator": "The fuel level has reached the reserve area.",
//   "automatic-gearbox":
//     "There are 4 modes: P, R, N, D/S for parked vehicle , Reverse gear, Neutral, Forward Drive/ Sports Program.",
//   "electric-parking-brake-warning-light":
//     "This sign comes It illuminates to show that the parking brake or emergency brake is engaged and must be released before driving.in infotainment when there is any kind of steep slope.",
//   "hazard-warning-light":
//     "Hazard warning lights are a pair of intermittent flashing indicator lights that flash in unison to warn other drivers that the vehicle is a temporary obstruction.",
//   "exterior-lighting-modes":
//     "The range of available light modes varies according to the ambient light conditions and vehicle operation (standing/driving).\r\nPress this switch repeatedly to select the desired mode.",
//   "turn-signal":
//     "Push the lever upwards for right signal and downwards for left signal.",
//   "central-locking-system":
//     "This button is present in the middle part of the control panel.\r\nThe button locks/unlocks all of the doors and the boot lid.",
//   "malfunction-indicator-light":
//     "Emission control system faulty - a noticeable reduction in engine performance may occur.",
//   "windscreen-defrost":
//     "This button is used for ventilation/defrosting of windscreen",
//   "electric-parking-brake-button":
//     "This button is used to engage the parking brake",
//   "park-steering-assistant":
//     "Park Assist helps drivers park in parallel and perpendicular parking places and to manoeuvre out of parallel parking spaces.",
//   "rear-defrost":
//     "This button is used for ventilation/defrosting of rear window.",
//   "seatbelt-warning":
//     "This light flashes up when front or rear seat belt is not fastened.",
//   "settings-button":
//     "This button is used to quickly enter into the settings menu.",
//   "assist-systems-instrument-cluster-controls":
//     "Buttons/dials on the multi-function steering wheel",
// };

export const Card = (props) => {
  // const property = {
  //   title: "Fuel Filter Warning Light",
  //   description:
  //     "There is water in the fuel filter, please drain it as soon as possible.",
  //   file:
  //     "https://s3.ap-south-1.amazonaws.com/maruti.data/Fuel_Filter_Warning_Light.png",
  //   full_description:
  //     "There is water in the fuel filter, please drain it as soon as possible. To see how to remove water please click on the link below or get your vehicle inspected by Maruti Suzuki Workshop.",
  //   links:
  //     "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Fuel_filter.mp4",
  // };

  // const {title,description,file} = props.data;

  const toTitleCase = (str) =>
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
      .join(' ');
  return (
    <Box width="sm" minH="150px" display="flex" borderWidth="1px" rounded="lg" overflow="hidden" className="neumorphic" flexDirection="row" w="100%" marginTop="1em">
      {/* <Box p="4" w="30%">
        <Image src={file} size="80px" alt='captured' />
      </Box> */}

      <Box d="flex" flexDirection="column" p="2" alignItems="center" flex="1">
        <Box className="title" style={{textAlign: 'left', width: '100%', color: 'white'}}>
          {toTitleCase(props.data.replace(/_/g, ' ').replace(/-/g, ' '))}
        </Box>

        <br />

        <Box d="flex" flexDirection="column" alignItems="center" style={{textAlign: 'left'}}>
          {videos[props.isVoice ? props.data : props.data] && (
            <Player fluid={false} width={320}>
              <source src={videos[props.isVoice ? props.data : props.data]} />
              <BigPlayButton position="center" />
            </Player>
          )}
        </Box>
        {feature_data[props.isVoice ? props.data : props.data] && (
          <Box className="description" style={{textAlign: 'left', color: '#DCDCDC'}}>
            {feature_data[props.isVoice ? props.data : props.data]}
          </Box>
        )}
      </Box>
    </Box>
  );
};

// export const Card = (props) => {
//   // const property = {
//   //   title: "Fuel Filter Warning Light",
//   //   description:
//   //     "There is water in the fuel filter, please drain it as soon as possible.",
//   //   file:
//   //     "https://s3.ap-south-1.amazonaws.com/maruti.data/Fuel_Filter_Warning_Light.png",
//   //   full_description:
//   //     "There is water in the fuel filter, please drain it as soon as possible. To see how to remove water please click on the link below or get your vehicle inspected by Maruti Suzuki Workshop.",
//   //   links:
//   //     "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Fuel_filter.mp4",
//   // };

//   return (
//     <Box
//       width="sm"
//       minH="150px"
//       display="flex"
//       borderWidth="1px"
//       rounded="lg"
//       overflow="hidden"
//       m="2em"
//       className="neumorphic"
//     >
//       {/* <Box p="4" w="30%">
//         <Image src={file} size="80px" alt='captured' />
//       </Box> */}

//       <Box d="flex" flexDirection="column" p="4">
//         <Box className="title" flexDirection="row">
//           Type of Tyre :{" "}
//           <span style={{ fontWeight: "normal" }}>
//             {props.data.type_of_tyre}
//           </span>
//         </Box>
//         <Box className="title" flexDirection="row">
//           Tyre Id:{" "}
//           <span style={{ fontWeight: "normal" }}>{props.data.tyre_id}</span>
//         </Box>
//         <Box className="title" flexDirection="row">
//           Defect Identified :{" "}
//           <span style={{ fontWeight: "normal" }}>
//             {props.data.detect_identified}
//           </span>
//         </Box>
//         <Box className="title" flexDirection="row">
//           Defect Classification :{" "}
//           <span style={{ fontWeight: "normal", textTransform: "capitalize" }}>
//             {props.data.detect_classification}
//           </span>
//         </Box>
//         <Box className="title" flexDirection="row">
//           Confidence :{" "}
//           <span style={{ fontWeight: "normal" }}>
//             {parseFloat(props.data.confidence * 100).toFixed(2)} %
//           </span>
//         </Box>
//         <Box className="title" flexDirection="row">
//           Inspection Summary :{" "}
//           <span
//             style={{
//               fontWeight: "normal",
//               color:
//                 props.data.inspection_summary == "Rejected"
//                   ? "red"
//                   : props.data.inspection_summary == "Accepted"
//                   ? "green"
//                   : "yellow",
//             }}
//           >
//             {props.data.inspection_summary}
//           </span>
//         </Box>
//         {/* <Box className="description">{description}</Box> */}
//       </Box>
//     </Box>
//   );
// };
