import React, { useLayoutEffect, useState } from "react";
//import Webcam from "react-webcam";
import { Box, Image } from "@chakra-ui/core";
import { MdPanoramaFishEye } from "react-icons/md";
import { withRouter } from "react-router-dom";
// import { Webcam } from "./webcam";
import "./camera.css";
import Cam from "./Cam";

class Camera extends React.Component {
  constructor() {
    super();
    this.webcam = null;
    this.webcamRef = React.createRef();
    this.state = {
      capturedImage: null,
      captured: false,
      uploading: false,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    // initialize the camera
    this.canvasElement = document.createElement("canvas");
    // this.webcam = new Webcam(
    //   document.getElementById("webcam"),
    //   this.canvasElement
    // );
    // this.webcam.setup().catch(() => {
    //   alert("Error getting access to your camera");
    // });

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  captureImage = async () => {
    const imageSrc = this.webcamRef.current.takePhoto();
    // const capturedData = await this.webcam.takeBase64Photo({
    //   type: "jpeg",
    //   quality: 0.8,
    // });
    // await this.webcam.takeBlobPhoto();
    console.log(imageSrc);
    this.props.history.push({
      pathname: "/results",
      state: { img: imageSrc },
    });
  };

  discardImage = () => {
    this.setState({
      captured: false,
      capturedImage: null,
    });
  };

  render() {
    // const buttons = !this.state.captured && (
    //   <CaptureButton left={window.innerWidth - 46}  onClick={this.captureImage}>
    //     {" "}
    //     Take Picture{" "}
    //   </CaptureButton>
    // );

    return (
      <div
      // style={{
      //   width: "auto",
      //   minWidth: "100vw",

      //   minHeight:"100vh",
      //   textAlign: "center",
      //   //backgroundColor:'black'
      // }}
      >
        {/* <video
          autoPlay
          playsInline
          muted
          id="webcam"
          width={window.screen.availWidth}
        /> */}

        <Box
          sx={{
            padding: "2%",
            // filter: status === STATUSES.LOADING ? "contrast(0.1)" : "unset",
          }}
        >
          <Cam
            webcamRef={this.webcamRef}
            facingMode="FACING_MODE_ENVIRONMENT"
          />
        </Box>
        <br />
        <Box
          as={MdPanoramaFishEye}
          size="72px"
          className="captureButton"
          onClick={this.captureImage}
          color="white"
        />
      </div>
    );
  }
}

export default withRouter(Camera);
