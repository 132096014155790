import React, {Component} from 'react';
import {Voice} from '../../components/Voice/Voice';
import axios from 'axios';

class VoiceContainer extends Component {
  state = {
    reply: ''
  };
  fetchResponse = async (msg) => {
    // let url = 'https://kkomh47ijuy6zprjajxagawehq0fgfnp.lambda-url.ap-south-1.on.aws/';
    let url = 'https://api.xanegin.assist.marutisuzuki.com/stage/query';
    let body = {
      // model_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/text/verbal-quantized.onnx',
      // labels_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/text/verbal.json',
      project: 'msil-assist',
      text: msg,
      limit: 1
    };
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'x-key': 'a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66'
      }
    };

    let response = await axios.post(url, body, options);
    return response.data.data.predictions[0].intent;
  };

  render() {
    return <Voice fetchResponse={this.fetchResponse} reply={this.state.reply} />;
  }
}

export default VoiceContainer;
