/* eslint-disable react/react-in-jsx-scope */

import { Box } from "@chakra-ui/core";
import React, { useState } from "react";
import { Camera } from "react-camera-pro";
import Webcam from "react-webcam";

function Cam({ webcamRef, facingMode }) {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const videoConstraints = {
    facingMode: { exact: facingMode },
    // width: { ideal: "auto" },
    // height: { ideal: "auto" },
    // aspectRatio: detectPlatform() === "ios" ? 0.6666666667 : 1,
    aspectRatio: 1,
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center" }} id="webcam">
      {/* <Webcam
        mirrored={facingMode === "user"}
        audio={false}
        screenshotQuality={1}
        videoConstraints={videoConstraints}
        ref={webcamRef}
        screenshotFormat="image/png"
      /> */}
      <Camera
        ref={webcamRef}
        aspectRatio={4 / 3}
        numberOfCamerasCallback={setNumberOfCameras}
        facingMode="environment"
      />
    </Box>
  );
}

export default Cam;
